<template>
  <v-layout v-if="_.size(detail) > 0" row wrap>
    <v-flex xs12 lg12 md12>
      <v-card>
        <titleCard title="Estados del producto" subtitle=""></titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap>
            <template v-if="!detail.status.hidden">
              <v-flex xs3>
                <v-switch v-model="detail.status.active" color="secondary" label="Activo" hide-details></v-switch>
              </v-flex>
              <v-flex xs3>
                <v-switch v-model="detail.status.color" color="secondary" label="Color" hide-details></v-switch>
              </v-flex>
              <v-flex xs3>
                <v-switch v-model="detail.status.important" color="secondary" label="Destacado" hide-details></v-switch>
              </v-flex>
              <v-flex xs3>
                <v-switch v-model="detail.status.sale" color="secondary" label="Promocion" hide-details></v-switch>
              </v-flex>
              <v-flex xs3>
                <v-switch
                  v-model="detail.status.personalize"
                  color="secondary"
                  label="Venta personalizada"
                  hide-details
                ></v-switch>
              </v-flex>
              <v-flex v-if="detail.status.personalize" xs9>
                <v-layout row wrap text-xs-center>
                  <v-flex xs4>
                    <v-text-field
                      v-model="detail.status.quantityDefault"
                      label="Cantidad por defecto"
                      type="number"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      v-model="detail.status.quantityMin"
                      label="Minimo de rosas"
                      type="number"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      v-model="detail.status.quantityMax"
                      label="Maximo de rosas"
                      type="number"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs3>
                <v-switch v-model="detail.status.stock" color="secondary" label="Control de stock"></v-switch>
                <v-text-field
                  v-if="detail.status.stock"
                  v-model="detail.status.quantity"
                  label="Cantidad"
                  type="number"
                ></v-text-field>
              </v-flex>
              <v-flex xs3>
                <v-switch
                  v-model="detail.status.daysAfter"
                  color="secondary"
                  label="Comprar con dias de anticipacion"
                ></v-switch>
                <v-text-field
                  v-if="detail.status.daysAfter"
                  v-model="detail.status.days"
                  label="Dias"
                  type="number"
                  prepend-icon="date_range"
                ></v-text-field>
              </v-flex>
            </template>
            <v-flex xs3>
              <v-switch v-model="detail.status.hidden" color="secondary" label="Deshabilitar"> </v-switch>
            </v-flex>
            <v-flex xs3>
              <v-switch
                v-model="detail.status.quarantine"
                style="margin: 0"
                color="secondary"
                label="Vender en comunas sin cuarentena"
              >
              </v-switch>
            </v-flex>
            <v-flex xs3>
              <v-switch v-model="detail.status.suscription" style="margin: 0" color="secondary" label="Suscripcion">
              </v-switch>
            </v-flex>
            <v-flex xs3>
              <v-switch v-model="detail.status.special" style="margin: 0" color="secondary" label="Venta especial">
              </v-switch>
            </v-flex>
            <v-flex xs3>
              <v-switch
                v-model="detail.status.box_color"
                style="margin: 0"
                color="secondary"
                label="Venta por selecion de color"
              >
              </v-switch>
            </v-flex>
            <v-flex v-if="detail.status.box_color" xs12>
              <v-layout row wrap text-xs-center>
                <v-flex xs4>
                  <v-text-field
                    v-model="detail.status.box_color_min"
                    label="Cantidad minima de selecion de color"
                    type="number"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="detail.status.box_color_max"
                    label="Cantidad maxima de selecion de color"
                    type="number"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="detail.status.box_color_mix"
                    label="Cantidad minima de mezcla de color"
                    type="number"
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3>
              <v-switch
                v-model="detail.status.death_type"
                style="margin: 0"
                color="secondary"
                label="Proceso de compra defuncion"
              >
              </v-switch>
            </v-flex>
            <v-flex xs3>
              <v-switch
                v-model="detail.status.facebook"
                style="margin: 0"
                color="secondary"
                label="Publicar en facebook"
              >
              </v-switch>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions align-end class="text-xs-right" bottom>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="save"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex v-if="products.length > 0" xs12 lg12 md12>
      <v-card>
        <titleCard title="Últimos pedidos ingresados" :subtitle="`${products.length} pedidos en total`"></titleCard>
        <v-divider></v-divider>
        <v-data-table
          :items="products"
          class="elevation-1"
          :headers="[
            { text: 'Pedido', align: 'left', sortable: false },
            { text: 'Estado', align: 'left', sortable: false },
            { text: 'Fecha de entrega', align: 'left', sortable: false },
            { text: 'Cantidad', align: 'right', sortable: false },
            { text: 'Valor', align: 'right', sortable: false }
          ]"
          hide-actions
        >
          <template v-slot:items="props">
            <td class="text-xs-left">
              <v-btn class="mx-0" color="primary" :to="{ name: 'orderHome', params: { id: props.item.order } }" small>
                {{ props.item.order }}
                <v-icon right dark small>launch</v-icon>
              </v-btn>
            </td>
            <td class="text-xs-right">{{ props.item.status.name }}</td>
            <td>{{ $moment(props.item.date_in).format('dddd DD-MM-YYYY') }}</td>
            <td class="text-xs-right">{{ props.item.quantity }}</td>
            <td class="text-xs-right">{{ props.item.value | toPrice }}</td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-flex v-if="logs.length > 0" xs12 lg12 md12>
      <v-card>
        <titleCard title="Últimos cambios" :subtitle="`${logs.length} modificaciones`"></titleCard>
        <v-divider></v-divider>
        <v-data-table
          :items="logs"
          class="elevation-1"
          :headers="[
            { text: 'Fecha', align: 'left', sortable: false },
            { text: 'Usuario', align: 'left', sortable: false },
            { text: 'Log', align: 'left', sortable: false }
          ]"
          hide-actions
        >
          <template v-slot:items="props">
            <td>
              {{ $moment(props.item.date_in).format('dddd DD-MM-YYYY') }}
              {{ $moment(props.item.date_in).format('HH:mm') }} hrs
            </td>
            <td class="text-xs-left">{{ props.item.user }}</td>
            <td class="text-xs-left">{{ props.item.log }}</td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { GET_PRODUCT } from '../../config'
import titleCard from '../useful/titleCard.vue'

export default {
  name: 'ProductStatus',
  components: { titleCard },
  props: ['product', 'getProduct'],
  data: () => ({
    loadingBtn: false,
    loading: false,
    active: true,
    stock: true,
    scheduleRetirement: 0,
    detail: {},
    states: [
      { name: 'Florida', abbr: 'FL', id: 1 },
      { name: 'Georgia', abbr: 'GA', id: 2 },
      { name: 'Nebraska', abbr: 'NE', id: 3 },
      { name: 'California', abbr: 'CA', id: 4 },
      { name: 'New York', abbr: 'NY', id: 5 }
    ],
    products: [],
    logs: []
  }),
  watch: {
    product() {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async save() {
      const { detail } = this
      try {
        const res = await this.$http.put(
          `${GET_PRODUCT}/${detail.id}/status`,
          { status: detail.status },
          {
            emulateJSON: true
          }
        )
        console.log(res)
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Estado actualizado'
        })
        this.getProduct()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getOrdersProduct() {
      if (this.detail.id > 0) {
        try {
          const res = await this.$http.get(`${GET_PRODUCT}/${this.detail.id}/order-by-stock-product`)
          this.products = res.data.orders
          this.logs = res.data.logs
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      }
    },
    init() {
      this.detail = this.product
      this.getOrdersProduct()
    }
  }
}
</script>
